import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const imageUrlBuilder = (url, height = null) => {
  var opts = "?"
  if (height) {
    opts = opts + `h=${height}`
  }
  return url + opts
}

const Slide = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`
const ImageSlide = styled(Slide)`
  height: min(40vh, 20ch);
  width: min(71vh, 35.56ch);
`

const GallerySlide = styled(Slide)`
  height: min(5vh, 5ch);
  width: min(10vh, 10ch);
  background-size: contain;
`

const SliderGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const MainSlider = styled(Slider)`
  width: min(768px, 100%);
`
const GallerySlider = styled(Slider)`
  width: min(768px, 100%);
`

export const ImageCarousel = ({ images, galleryMode = true }) => {
  var mainSettings = {
    arrows: true,
  }
  var gallerySettings = {
    slidesToShow: images.length > 5 ? 5 : images.length,
    slidesToScroll: 2,
    dots: true,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
  }
  const [mainRef, setMainRef] = React.useState()
  const [galleryRef, setGalleryRef] = React.useState()
  return (
    <SliderGroup>
      <MainSlider
        {...mainSettings}
        asNavFor={galleryRef}
        ref={ref => setMainRef(ref)}
        className="mainSlider"
      >
        {images?.map(v => (
          <ImageSlide
            key={"main" + v._key}
            src={imageUrlBuilder(v.asset.url)}
          ></ImageSlide>
        ))}
      </MainSlider>
      {galleryMode && images.length > 1 ? (
        <GallerySlider
          {...gallerySettings}
          ref={ref => setGalleryRef(ref)}
          asNavFor={mainRef}
        >
          {images?.map(v => (
            <GallerySlide
              key={"gallery" + v._key}
              src={imageUrlBuilder(v.asset.url, 200)}
            ></GallerySlide>
          ))}
        </GallerySlider>
      ) : null}
    </SliderGroup>
  )
}
