// block serializer

import React from "react"
import getYouTubeId from "get-youtube-id"
import Player from "react-player"
import { imageUrlFor } from "./helper"
import styled from "styled-components"
import { ImageCarousel } from "../components/ImageCarousel"

import Cta from "../components/Cta"
import { Card } from "../components/Card"
import { CardGrid } from "../components/CardGrid"

// styled image container for inline images within a landing page text block
const ImageContainer = styled.div`
  width: 505px;
  max-width: 50%;
  /* margins according to image position inside the text block (right, left, center): */ 
  margin-right: ${props => (props.position === "right" ? "0px" : "25px")};
  margin-left: ${props => (props.position === "left" ? "0px" : "25px")};
  ${props => (props.position === "center" ? "margin: 0 auto;" : "")}
  /* float according to image position inside text block */ 
  float: ${props => (props.position === "center" ? "unset" : props.position)};
  img {
    max-width: 100%;
  }
  /* mobile styling */ 
  @media (max-width: ${props => props.theme.mobile}){
    width: 100%;
    max-width: unset;
    float: center;
  }
`

// image component for the image container
const ImageComponent = ({ node }) => {
  return (
    <ImageContainer position={node.position}>
      <img
        src={imageUrlFor(node).width(1200).auto("format").fit("scale").url()}
        alt={node.alt}
      />
      {node.caption && <span className="image-caption">{node.caption}</span>}
    </ImageContainer>
  )
}

// button component for the inline cta button
const ButtonComponent = ({ node }) => {
  return (
    <Cta
      // if it links to a contact form, append the contact form title to the href; else use the url
      href={
        node.contactForm
          ? `/contactFormDynamic?&formId=${node.contactForm.title}`
          : node.url
      }
    >
      {node.action}
    </Cta>
  )
}

// styled div container for youtube plugin
const MediaPlayerContainer = styled.div`
  /* styling for video container to keep 16:9 ratio when scaled in size */
  position: relative;
  overflow-y: hidden;
  justify-content: center;
  display: flex;
  height: ${props => (props.audioOnly ? "auto" : "max(9vmin, 360px)")};
  & > div {
    max-width: max(16vmin, 640px);
    height: ${props => (props.audioOnly ? "auto" : "max(9vmin, 360px)")};
  }
  & audio {
    min-height: max(50px, 1rem);
  }
  & div {
    overflow: hidden;
  }
  /* mobile styling */
  @media (max-width: ${({ theme }) => theme.mobile}) {
    /* */
  }
`

// youTube component for the youTube container
const MediaPlayerComponent = ({ node }) => {
  var { url, mediaFile, audioOnly } = node
  console.log(node)
  if (mediaFile) {
    url = mediaFile.asset.url
  }
  const config = {
    file: {
      forceAudio: audioOnly,
    },
  }
  return (
    <MediaPlayerContainer audioOnly={audioOnly}>
      <Player url={url} controls config={config} width="100%" height="100%" />
    </MediaPlayerContainer>
  )
}

const ImageCarouselComponent = ({ node }) => {
  const { images } = node
  return <ImageCarousel images={images} />
}

const CardComponent = ({ node }) => {
  const { title, description, image, position, link } = node
  return (
    <Card
      title={title}
      description={description}
      image={image}
      position={position}
      linkTo={link}
    />
  )
}

const CardGridComponent = ({ node }) => {
  return <CardGrid cards={node.cards} />
}

// block serializer
const blockSerializer = {
  types: {
    inlineImage: ImageComponent,
    inlineCta: ButtonComponent,
    youtube: MediaPlayerComponent,
    soundcloud: MediaPlayerComponent,
    mediaPlayer: MediaPlayerComponent,
    imageCarousel: ImageCarouselComponent,
    card: CardComponent,
    cardGrid: CardGridComponent,
  },
}

export default blockSerializer
