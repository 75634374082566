import React from "react"
import styled from "styled-components"
import { Card as BSCard, Row, Col, Container } from "react-bootstrap"

const BSCardWrapper = styled.div`
  display: ${props => (props.position ? "grid" : "inline-block")};
  justify-items: ${props => props.position};
  width: 100%;
  background: salmon;
`

export const Card = ({ title, description, image, position, linkTo }) => {
  const CardElement = () => (
    <BSCard>
      {image ? <BSCard.Img variant="top" src={image.asset.url} /> : null}
      <BSCard.Body>
        <BSCard.Title>{title}</BSCard.Title>
        <BSCard.Text>{description}</BSCard.Text>
      </BSCard.Body>
      {linkTo ? (
        <BSCard.Footer>
          <BSCard.Link href={linkTo}>See more.</BSCard.Link>
        </BSCard.Footer>
      ) : null}
    </BSCard>
  )
  return (
    <>
      {position ? (
        <Container position={position}>
          <Row>
            <Col sm={12} md={{ span: 6, offset: 3 }}>
              <CardElement />
            </Col>
          </Row>
        </Container>
      ) : (
        <CardElement />
      )}
    </>
  )
}
