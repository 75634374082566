import React from "react"
import { CardColumns, Row, Col } from "react-bootstrap"
import { Card } from "./Card"
import styled from "styled-components"

const GridClear = styled.div`
  display: block;
`

export const CardGrid = ({ cards }) => {
  return (
    <GridClear>
      <Row>
        <Col>
          <CardColumns>
            {cards?.map(v => (
              <Card
                title={v.title}
                description={v.description}
                image={v.image}
                linkTo={v.link}
              />
            ))}
          </CardColumns>
        </Col>
      </Row>
    </GridClear>
  )
}
